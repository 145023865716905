import { useState, useMemo } from "react";

function loadImage(src)
{
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.onerror = image.onabort = () => {
      reject(src)
    }
    image.src = src;
  });
}

export default function useImageLoader(images)
{
  const [imagesAreLoaded, setImagesAreLoaded] = useState(false);
  useMemo(() => {
    let canceled = false;
    if (!imagesAreLoaded) {
      (async () => {
        if (canceled) {
          return;
        }
        const promises = images.map(image => loadImage(image));
        await Promise.all(promises);
        if (canceled) {
          return;
        }
        setImagesAreLoaded(true);
      })();
    }
    return () => {
      canceled = true;
    };
  }, []);
  return { imagesAreLoaded };
}
